import React, { ChangeEvent, useState } from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import { CreateCategorySchema } from '../../../validation/validate'
import {
  useCreateProductCategoryMutation,
  useEditProductCategoryMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import { ImageBanner } from '../../images/imageBanner'
import { ImageUploadButton } from '../../button/imageUploadButton'
import { useModalControl } from '../../../hooks/useModalControl'
import { ImageUploadPreviewModal } from './ImagePreviewModal'

export const AddCategoryModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: () => void
  mode: 'edit' | 'create'
  category?: ListCategoryDoc
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = () => {},
  mode,
  category,
}) => {
  const [uploadedIcon, setUploadedIcon] = useState<File>()

  const [createCategory, { isLoading: creating }] =
    useCreateProductCategoryMutation()
  const [editCategory, { isLoading: editing }] =
    useEditProductCategoryMutation()

  const { handleModalOpen, modal, modalOpen, setModalOpen, handleModalClose } =
    useModalControl()

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }

    const selectedFile = event.target.files[0]

    setUploadedIcon(selectedFile)

    handleModalOpen('imageUploadPreviewModal')
  }

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      icon: category?.icon ?? '',
      name: category?.name ?? '',
      description: category?.description ?? '',
      comboTagLine: category?.comboTagLine ?? '',
      recommendedTagLine: category?.recommendedTagLine ?? '',
    },
    enableReinitialize: true,
    validationSchema: CreateCategorySchema,
    onSubmit: (values) => {
      if (mode === 'create') {
        createCategory(values).then((resp) => {
          if (resp.data?.success) {
            resetForm()
            setOpen(false)
            onSuccessCallback()
          }
        })
      } else if (mode === 'edit' && category) {
        editCategory({ id: category?._id, payload: values }).then((resp) => {
          if (resp.data?.success) {
            resetForm()
            setOpen(false)
            onSuccessCallback()
          }
        })
      }
    },
  })
  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={mode === 'create' ? 'Add new category' : 'Edit category'}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <div className="relative max-w-fit">
          <ImageBanner
            imageUrl={values.icon}
            loading={false}
            className="
            !rounded-full
            !w-[120px] !max-h-[120px]
            "
            errors={errors.icon ? errors.icon : ''}
          />
          <ImageUploadButton
            handleChange={(e) => {
              handleFileChange(e)
            }}
            showText={false}
            className="
            !left-0 !right-0 !top-[calc(50%-20px)]
            ml-auto mr-auto !max-w-fit
            !px-2
            "
          />
        </div>

        <TextInput
          type="text"
          name="name"
          placeholder="Category name"
          value={values.name}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={errors.name && touched.name ? errors.name : ''}
          hasIcon={false}
          className="!border-divider-gray"
        />

        <TextInput
          type="textarea"
          name="description"
          placeholder="Category description"
          value={values.description}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={
            errors.description && touched.description ? errors.description : ''
          }
          hasIcon={false}
          className="!border-divider-gray"
        />

        <TextInput
          type="textarea"
          name="comboTagLine"
          placeholder="Combo tag text"
          value={values.comboTagLine}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={
            errors.comboTagLine && touched.comboTagLine
              ? errors.comboTagLine
              : ''
          }
          hasIcon={false}
          className="!border-divider-gray"
        />

        <TextInput
          type="textarea"
          name="recommendedTagLine"
          placeholder="Recommended tag text"
          value={values.recommendedTagLine}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={
            errors.recommendedTagLine && touched.recommendedTagLine
              ? errors.recommendedTagLine
              : ''
          }
          hasIcon={false}
          className="!border-divider-gray"
        />

        <Button
          label="Proceed"
          type="submit"
          className="mt-10"
          disabled={!isValid || creating || editing}
          loading={creating || editing}
          primary
        />
      </form>

      {uploadedIcon ? (
        <ImageUploadPreviewModal
          image={uploadedIcon as File}
          open={modal === 'imageUploadPreviewModal' && modalOpen}
          setOpen={setModalOpen}
          onCloseCallback={() => {
            setUploadedIcon(undefined)
          }}
          onSuccessCallback={(url) => {
            setFieldValue('icon', url)
          }}
        />
      ) : null}
    </ModalComponent>
  )
}
