/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  configureStore,
  combineReducers,
  type Middleware,
  isRejectedWithValue,
} from '@reduxjs/toolkit'
import {
  FLUSH,
  persistReducer,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

import { toast } from 'react-toastify'

import { apiSlice, goodOnlineApiSlice, imagesApiSlice } from '../api/apiSlice'
import Cookies from 'js-cookie'
import authReducer, { signOut } from '../auth/auth.reducer'
import storage from 'redux-persist/lib/storage'
import { COOKIE_KEY } from '../../constants'
import userReducer from '../user/user.reducer'
import { vendorsApiSlice } from '../vendors/vendors.slice'
import { orderSlice } from '../orders/order.reducer'

/**
 * @description Middleware for logging or toast notifications
 * @returns next func
 */
export const rtkQueryResponseFeedbackMiddleware: Middleware =
  () => (next) => (action) => {
    const dispatch = store.dispatch
    // returns action type: query || mutation

    const actionType = (): string => (action as any)?.meta?.arg?.type

    if (actionType() === 'mutation') {
      if (isRejectedWithValue(action)) {
        if ((action as any)?.payload?.data?.error?.includes('authorization')) {
          Cookies.remove(COOKIE_KEY)
          dispatch(signOut())
          toast.error('Session expired, please login', { theme: 'colored' })
        }
      }
    }

    if (actionType() === 'query') {
      if (isRejectedWithValue(action)) {
        if ((action as any)?.payload?.data?.error?.includes('authorization')) {
          Cookies.remove(COOKIE_KEY)
          dispatch(signOut())
          window.location.href = '/auth/login'
        }
      }
    }

    return next(action)
  }

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  [vendorsApiSlice.reducerPath]: vendorsApiSlice.reducer,
  [goodOnlineApiSlice.reducerPath]: goodOnlineApiSlice.reducer,
  [imagesApiSlice.reducerPath]: imagesApiSlice.reducer,
  auth: authReducer,
  user: userReducer,
  [orderSlice.reducerPath]: orderSlice.reducer,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware): any =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      apiSlice.middleware,
      vendorsApiSlice.middleware,
      goodOnlineApiSlice.middleware,
      imagesApiSlice.middleware,
      rtkQueryResponseFeedbackMiddleware,
    ]),
  devTools: true,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
