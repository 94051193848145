import { toast } from 'react-toastify'
import { apiSlice } from '../api/apiSlice'
import {
  type ResetPasswordPayload,
  type LoginPayload,
  type AuthResponse,
  type UserPayload,
  ErrorType,
} from '../user/user.type'
import { setAuthUserData } from './auth.reducer'
import { getErrorMessage } from '../../helpers'

interface PostResquestPayload {
  data: null
  message: string
  success: boolean
}

interface RealPayload {
  data: AuthResponse
  message: string
  success: boolean
}

export const authApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    login: builder.mutation<RealPayload, LoginPayload>({
      query: (payload) => ({
        url: `/auth/login`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: RealPayload) => response,
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled
          if (res.data.success) {
            toast.success(res.data.message)
            dispatch(setAuthUserData(res.data.data))
          }
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    registerUser: builder.mutation<RealPayload, UserPayload>({
      query: (payload) => ({
        url: `/auth/register`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'USER', id: 'LIST' }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled
          if (res.data.success) {
            toast.success(res.data.message)
          }
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    forgotPassword: builder.mutation<PostResquestPayload, { email: string }>({
      query: (payload) => ({
        url: `/auth/forgot-password`,
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled
          if (res.data.success) {
            toast.success(res.data.message)
          }
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    resetPassword: builder.mutation<PostResquestPayload, ResetPasswordPayload>({
      query: (payload) => ({
        url: `/auth/reset-password`,
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled
          if (res.data.success) {
            toast.success(res.data.message)
          }
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
  }),
})

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useRegisterUserMutation,
} = authApiSlice
