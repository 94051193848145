import React, { useEffect, useState } from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import {
  CreateCategoryPayload,
  CreateDiscount,
  DiscountType,
  SingleDiscount,
  StatusType,
  ValueType,
} from '../../../redux-toolkits/products/products.type'
import { useModalControl } from '../../../hooks/useModalControl'
import {
  AddDiscountSchema,
  addLocationManualSchema,
  CreateCategorySchema,
} from '../../../validation/validate'
import {
  useCreateDiscountMutation,
  useCreateProductCategoryMutation,
  useEditDiscountMutation,
  useEditProductCategoryMutation,
  useGetSingleDiscountsQuery,
  useLazyGetAllProductsQuery,
  useLazyGetSingleDiscountsQuery,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import AddressInput from '../../inputs/addressInput'
import { useAddAvailableLocationsMutation } from '../../../redux-toolkits/user/user.slice'
import { AddAvailableLocationsPayload } from '../../../redux-toolkits/user/user.type'
import { TabNav } from '../../nav/tabNav'
import Dropdown from '../../inputs/dropdown'
import Icon from '../../icon'
import { SelectProductModal } from './selectProductModal'
import { EmptyState } from '../../emptyState/emptyState'
import { formatCurrency, removeElementAtIndex } from '../../../helpers'
import { PRIMARY_COLOR } from '../../../constants'
import Spinner, { LoadingOval } from '../../spinner/Spinner'
import { Checkbox } from '../../inputs/Checkbox'
import Pagination from '../../pagination'
import { useLazyFetchListsQuery } from '../../../redux-toolkits/lists/list.slice'
import { Product } from '../../../types/types'

const types: DiscountType[] = ['items', 'delivery', 'cart']
const valueType: ValueType[] = ['percent', 'constant']
const statusType: StatusType[] = ['inactive', 'active']

export const AddDiscountModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: () => void
  edit?: boolean
  discountId?: string
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = () => {},
  edit = false,
  discountId,
}) => {
  const [selectedDiscount, setSelectedDiscount] = useState<
    SingleDiscount | undefined
  >()

  const [createDiscount, { isLoading: creating }] = useCreateDiscountMutation()
  const [editDiscount, { isLoading: editing }] = useEditDiscountMutation()

  const [fetchDiscount, { isFetching: fetchingSingleDiscount }] =
    useLazyGetSingleDiscountsQuery()

  const { modal, modalOpen, handleModalOpen, setModalOpen } = useModalControl()

  const {
    values,
    errors,
    handleBlur,
    setFieldValue,
    handleChange,
    handleSubmit,
    touched,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      minimumAmount: '',
      maximumAmount: '',
      type: '',
      items: [],
      valueType: '',
      code: '',
      value: '',
      usageLimit: '',
      userUsageLimit: '',
      status: '',
    },
    enableReinitialize: true,
    validationSchema: AddDiscountSchema,
    onSubmit: (vals) => {
      const type: DiscountType = vals.type as DiscountType
      const valueType: ValueType = vals.valueType as ValueType
      const status: StatusType = vals.status as StatusType
      const payload: Partial<CreateDiscount> = {
        minimumAmount: Number(vals.minimumAmount),
        maximumAmount: Number(vals.maximumAmount),
        value: vals.value,
        code: vals.code,
        items:
          type === 'items' && Array.isArray(vals.items)
            ? vals.items.map((product_) => {
                const product = product_ as unknown as Product
                return product._id
              })
            : undefined,
        valueType,
        usageLimit: Number(vals.usageLimit),
        userUsageLimit: Number(vals.userUsageLimit),
        type,
        status,
      }

      edit
        ? editDiscount({
            id: discountId ?? '',
            data: payload as CreateDiscount,
          })?.then((resp) => {
            if (resp.data?.success) {
              resetForm()
              onSuccessCallback()
            }
          })
        : createDiscount(payload as CreateDiscount)?.then((resp) => {
            if (resp.data?.success) {
              resetForm()
              onSuccessCallback()
            }
          })
    },
  })

  useEffect(() => {
    if (open && edit && discountId) {
      fetchDiscount({ id: discountId }).then((resp) => {
        if (resp.data?.success) {
          setFieldValue(
            'minimumAmount',
            String(resp.data.data.discount.minimumAmount),
          )
          setFieldValue(
            'maximumAmount',
            String(resp.data.data.discount.maximumAmount),
          )
          setFieldValue('type', resp.data.data.discount.type)
          setFieldValue('items', resp.data.data.discount.items)
          setFieldValue('valueType', resp.data.data.discount.valueType)
          setFieldValue('code', resp.data.data.discount.code)
          setFieldValue(
            'value',
            resp.data.data.discount.value
              ? String(resp.data.data.discount.value)
              : undefined,
          )
          setFieldValue(
            'usageLimit',
            resp.data.data.discount.usageLimit
              ? String(resp.data.data.discount.usageLimit)
              : undefined,
          )
          setFieldValue(
            'userUsageLimit',
            resp.data.data.discount.userUsageLimit
              ? String(resp.data.data.discount.userUsageLimit)
              : undefined,
          )
          setFieldValue('status', String(resp.data.data.discount.status))
        }
      })
    }
  }, [edit, discountId, open])

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        setFieldValue('items', [])
        resetForm()
        onCloseCallback()
      }}
      title={`Add new discount`}
      position="right"
    >
      {fetchingSingleDiscount ? (
        <Spinner fullScreen={false} />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="h-full flex flex-col justify-between"
        >
          <div className="flex flex-col gap-4 overflow-y-scroll flex-1">
            <div className="grid grid-cols-2 gap-4">
              <Dropdown
                menuButton={
                  <TextInput
                    type="text"
                    name="type"
                    placeholder="Select type"
                    value={values.type}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    hasIcon={false}
                    readOnly
                    extra={
                      <Icon
                        id={'left-caret'}
                        width={16}
                        height={16}
                        className="-rotate-90 text-sec-black"
                      />
                    }
                  />
                }
                onClickMenuItem={(selection) => {
                  setFieldValue('type', selection.value)
                }}
                menuItems={types.map((type) => {
                  return {
                    name: type,
                    value: type,
                  }
                })}
              />

              <Dropdown
                menuButton={
                  <TextInput
                    type="text"
                    name="status"
                    placeholder="Select status"
                    value={values.status}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    hasIcon={false}
                    readOnly
                    extra={
                      <Icon
                        id={'left-caret'}
                        width={16}
                        height={16}
                        className="-rotate-90 text-sec-black"
                      />
                    }
                  />
                }
                onClickMenuItem={(selection) => {
                  setFieldValue('status', selection.value)
                }}
                menuItems={statusType.map((status) => {
                  return {
                    name: status,
                    value: status,
                  }
                })}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <TextInput
                title="Minimum amount"
                type="text"
                name="minimumAmount"
                placeholder="Minimum amount"
                value={values.minimumAmount}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={
                  errors.minimumAmount && touched.minimumAmount
                    ? errors.minimumAmount
                    : ''
                }
                hasIcon={false}
                className="!border-divider-gray"
              />

              <TextInput
                title="Maximum amount"
                type="text"
                name="maximumAmount"
                placeholder="Maximum amount"
                value={values.maximumAmount}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={
                  errors.maximumAmount && touched.maximumAmount
                    ? errors.maximumAmount
                    : ''
                }
                hasIcon={false}
                className="!border-divider-gray"
              />
            </div>

            {values.type === 'items' && (
              <div className="flex justify-end">
                <Button
                  label={'Select items'}
                  type="button"
                  className="!w-fit"
                  onClick={() => {
                    handleModalOpen('selectProductModal')
                  }}
                  size="small"
                  icon="plus"
                />
              </div>
            )}

            {values.type === 'items' && (
              <div className="flex flex-col overflow-y-scroll min-h-[300px] p-4">
                {values.items.length > 0 ? (
                  values.items.map((product_, idx) => {
                    const product: Product = product_ as unknown as Product
                    return (
                      <div
                        key={product._id}
                        className="flex gap-2 items-center border-b border-divider-gray py-2 first:pt-0"
                      >
                        <img
                          alt={product.name}
                          src={product.images[0]}
                          className="w-[50px] h-[50px] object-cover rounded-md overflow-hidden border"
                        />
                        <div className="flex-1">
                          <span className="">{product.name}</span>
                        </div>
                        <div className="text-sm text-sec-black">
                          {formatCurrency(product.price)}
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            const productsCopy = JSON.parse(
                              JSON.stringify(values.items),
                            )
                            setFieldValue(
                              'items',
                              removeElementAtIndex(productsCopy, idx),
                            )
                          }}
                          className="text-red-500"
                        >
                          <Icon
                            className="text-danger"
                            width={16}
                            height={16}
                            id="bin"
                          />
                        </button>
                      </div>
                    )
                  })
                ) : (
                  <EmptyState text="No products selected" />
                )}
              </div>
            )}

            <>
              <div className="grid grid-cols-2 gap-4">
                <TextInput
                  title="Value"
                  type="text"
                  name="value"
                  placeholder="Input value"
                  value={values.value}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={errors.value && touched.value ? errors.value : ''}
                  hasIcon={false}
                  className="!border-divider-gray"
                />

                <Dropdown
                  menuButton={
                    <TextInput
                      type="text"
                      name="type"
                      placeholder="Select value type"
                      value={values.valueType}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      hasIcon={false}
                      readOnly
                      extra={
                        <Icon
                          id={'left-caret'}
                          width={16}
                          height={16}
                          className="-rotate-90 text-sec-black"
                        />
                      }
                    />
                  }
                  onClickMenuItem={(selection) => {
                    setFieldValue('valueType', selection.value)
                  }}
                  menuItems={valueType.map((value) => {
                    return {
                      name: value,
                      value: value,
                    }
                  })}
                />
              </div>

              <TextInput
                title="Code"
                type="text"
                name="code"
                placeholder="Input Code"
                value={values.code}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors.code && touched.code ? errors.code : ''}
                hasIcon={false}
                className="!border-divider-gray"
              />

              <div className="grid grid-cols-2 gap-4">
                <TextInput
                  title="Usage Limit"
                  type="text"
                  name="usageLimit"
                  placeholder="Usage limit"
                  value={values.usageLimit}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={
                    errors.usageLimit && touched.usageLimit
                      ? errors.usageLimit
                      : ''
                  }
                  hasIcon={false}
                  className="!border-divider-gray"
                />

                <TextInput
                  title="User usage limit"
                  type="text"
                  name="userUsageLimit"
                  placeholder="Input user limit"
                  value={values.userUsageLimit}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={
                    errors.userUsageLimit && touched.userUsageLimit
                      ? errors.userUsageLimit
                      : ''
                  }
                  hasIcon={false}
                  className="!border-divider-gray"
                />
              </div>
            </>

            {modal === 'selectProductModal' && modalOpen && (
              <SelectProductModal
                open={modal === 'selectProductModal' && modalOpen}
                setOpen={setModalOpen}
                onCloseCallback={() => {
                  setModalOpen(false)
                }}
                onSuccessCallback={(product: Product) => {
                  const productsCopy: Product[] = JSON.parse(
                    JSON.stringify(values.items),
                  )

                  const isProductSelected = productsCopy.some(
                    (selectedProduct) => selectedProduct._id === product._id,
                  )

                  if (!isProductSelected) {
                    productsCopy.push(product)
                    setFieldValue('items', productsCopy)
                  }
                }}
              />
            )}
          </div>

          <Button
            label="Proceed"
            type="submit"
            className="mt-10 w-full"
            disabled={creating || editing || !isValid}
            loading={creating || editing}
            primary
          />
        </form>
      )}
    </ModalComponent>
  )
}
