import React from 'react'
import { CircleSlash } from 'lucide-react'
import { format } from 'date-fns'
import { formatCurrency } from '../../helpers'
import { Transactions } from '../../types/types'
import { TableComponent } from '../../components/table'
import { Status } from '../../components/cards/statusTag'

interface Props {
  transactionList: Transactions[]
  currentPage?: number
  totalDataCount?: number
  pageLimit?: number
  onPageChange?: (page: number) => void
  loading: boolean
}

const TransactionsTable: React.FC<Props> = ({
  transactionList,
  currentPage,
  totalDataCount,
  pageLimit,
  onPageChange,
  loading,
}) => {
  return (
    <TableComponent
      headers={['Reference', 'Amount', 'Payment method', 'Status', 'Date']}
      name="transactions"
      rows={transactionList.map((transaction) => {
        return {
          id: transaction._id,
          content: [
            transaction.reference,
            formatCurrency(transaction.amount),
            transaction.type,
            <Status
              key={`${transaction._id}-status`}
              text={transaction.status}
              type={
                transaction.status.toLowerCase() === 'completed'
                  ? 'success'
                  : 'warn'
              }
            />,
            format(new Date(transaction.createdAt), 'dd/MM/yyyy h:mma'),
          ],
        }
      })}
      currentPage={currentPage}
      totalDataCount={totalDataCount}
      pageLimit={pageLimit}
      onPageChange={onPageChange}
      loading={loading}
    />
  )
}

export default TransactionsTable
