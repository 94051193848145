import React, { Component, ReactNode } from 'react'
import { Button } from '../button'
import { Link } from 'react-router-dom'

interface State {
  hasError: boolean
  errorInfo: string | null
}

interface Props {
  children: ReactNode
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false, errorInfo: null }
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state to indicate an error has occurred
    return { hasError: true, errorInfo: null }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error('Error occurred:', error, errorInfo)
    this.setState({ errorInfo: errorInfo.componentStack ?? null })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className="
      flex flex-col 
      justify-center items-center 
      h-screen
      gap-4 p-5
      "
        >
          <img src="/assets/images/404.svg" alt="404" />

          <div className="flex flex-col items-center justify-center text-center gap-2">
            <p className="text-2xl font-bold">Something went wrong</p>
            <p></p>
          </div>

          <Link to={'/dashboard'}>
            <Button
              type="button"
              label="Go home"
              className="w-full max-w-[300px]"
              primary={false}
              onClick={() => {}}
            />
          </Link>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
