import React from 'react'
import { format } from 'date-fns'
import { Avatar } from '../../components/avatar'
import { formatCurrency } from '../../helpers'
import { type OrderResponse } from '../../redux-toolkits/orders/orders.type'
import { DISABLE__DELIVERY_REQUEST__FROM, ORDERSTATUS } from '../../constants'
import { useRequestOrderDeliveryMutation } from '../../redux-toolkits/orders/orders.slice'
import { Link } from 'react-router-dom'
import { TableComponent } from '../../components/table'
import { Status } from '../../components/cards/statusTag'
import { StatusTypes } from '../../types/types'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'

interface Props {
  orders: OrderResponse[]
  currentPage?: number
  totalDataCount?: number
  pageLimit?: number
  onPageChange?: (page: number) => void
  loading?: boolean
  showCustomerName?: boolean
  name?: string
  showName?: boolean
}

const OrderTable: React.FC<Props> = ({
  orders,
  currentPage,
  totalDataCount,
  pageLimit,
  onPageChange,
  loading,
  showCustomerName = false,
  name = 'Orders',
  showName,
}): JSX.Element => {
  const [requestDelivery, { isLoading }] = useRequestOrderDeliveryMutation()

  const handleRequestDelivery = async (id: string): Promise<void> => {
    await requestDelivery({ id })
  }

  return (
    <div className="">
      <TableComponent
        name={name}
        showName={showName}
        headers={[
          'Order number',
          'Amount',
          showCustomerName ? 'Customer' : undefined,
          'Date',
          'Status',
          'ACTIONS',
        ]}
        rows={orders.map((order) => {
          return {
            id: order.order._id,
            content: [
              <Link
                to={`/orders/${order.order._id}`}
                className="
                hover:text-black hover:underline
                transition-all duration-500
                "
                key={`${order.order._id}-product-id`}
              >
                #{order.order.orderNumber}
              </Link>,
              `${formatCurrency(order.order.amount)}`,
              showCustomerName ? (
                <div
                  className="flex items-center space-x-3"
                  key={`${order.order._id}-customer`}
                >
                  <Avatar
                    name={`${order.order.personalInformation.firstName} ${order.order.personalInformation.lastName}`}
                    useRandomBgColor
                  />
                  <span>
                    {`${order.order.personalInformation.firstName} ${order.order.personalInformation.lastName}`}
                  </span>
                </div>
              ) : undefined,
              `${format(order.order.createdAt, 'dd/MM/yyyy h:mma')}`,
              <div
                key={`${order.order._id}-status`}
                className="flex items-center gap-2 overflow-visible"
              >
                <Status
                  text={order.order.status.toLowerCase()}
                  type={
                    (ORDERSTATUS.find(
                      (status) =>
                        status.name.toLowerCase() ===
                        order.order.status.toLowerCase(),
                    )?.type ?? 'warn') as StatusTypes
                  }
                />
              </div>,
              <Dropdown
                key={`${order.order._id}-controls`}
                menuButton={
                  <Icon id="ellipses" height={18} width={18} className="" />
                }
                onClickMenuItem={() => {}}
                menuItems={[
                  {
                    name: (
                      <button
                        className="
                        disabled:opacity-30
                        disabled:cursor-not-allowed
                        w-full text-left
                        "
                        onClick={(): any =>
                          handleRequestDelivery(order.order._id)
                        }
                        disabled={
                          isLoading ||
                          (ORDERSTATUS.find(
                            (stat) =>
                              stat.name.toLowerCase() ===
                              order.order.status.toLowerCase(),
                          )?.id ?? Infinity) >= DISABLE__DELIVERY_REQUEST__FROM
                        }
                      >
                        Request delivery
                      </button>
                    ),
                    value: '',
                  },
                ]}
              />,
            ],
          }
        })}
        isEmpty={[undefined, 0].includes(orders.length)}
        loading={loading}
        currentPage={currentPage}
        totalDataCount={totalDataCount}
        pageLimit={pageLimit}
        onPageChange={onPageChange}
      />
    </div>
  )
}

export default React.memo(OrderTable)
