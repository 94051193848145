import React from 'react'
import Icon from '../icon'
import { Avatar } from '../avatar'
import { Link } from 'react-router-dom'
import { signOut } from '../../redux-toolkits/auth/auth.reducer'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { authSelector } from '../../redux-toolkits/auth/auth.selector'
import { Logo } from '../logo'
import Dropdown from '../inputs/dropdown'

const TopNavigation = ({
  navOpen,
  setNavOpen,
}: {
  navOpen?: boolean
  setNavOpen?: (val: boolean) => void
}): JSX.Element => {
  const dispatch = useDispatch()

  const handleLogout = (): void => {
    dispatch(signOut())
  }

  const { user } = useAppSelector(authSelector)

  return (
    <div
      className="
      px-4 md:px-10 py-5
      flex justify-between items-center 
      border-b
      !z-[999]
      w-full
      "
    >
      <div className="flex items-center gap-6">
        {user?.firstname && user.lastname && (
          <button
            onClick={() => {
              setNavOpen && setNavOpen(!navOpen)
            }}
          >
            <Icon
              id={navOpen ? 'close-icon' : 'menu-icon'}
              width={navOpen ? 20 : 30}
              height={navOpen ? 20 : 30}
              className="transition-all duration-300 flex xl:hidden"
            />
          </button>
        )}

        <Logo />
      </div>

      {user?.firstname && user.lastname ? (
        <div
          className="
        flex items-center justify-between 
        text-black
        gap-6
        "
        >
          <Icon id="notification-icon" height={20} width={20} />

          <Dropdown
            menuButton={
              <Avatar name={`${user?.firstname} ${user?.lastname}`} />
            }
            onClickMenuItem={() => {}}
            menuItems={[
              {
                name: (
                  <div>
                    <span className="text-base font-recoleta-medium">{`${user?.firstname} ${user?.lastname}`}</span>
                    <div
                      className="
                      text-sm text-sec-black
                      flex justify-between items-center
                      space-x-2
                      "
                    >
                      <span className="text-xs">{user?.email}</span>
                    </div>
                  </div>
                ),
                value: '',
              },
              {
                name: (
                  <button
                    onClick={handleLogout}
                    className="text-danger flex items-center gap-2 w-full"
                  >
                    <Icon id="logout2-icon" height={25} width={25} />
                    <span>Sign out</span>
                  </button>
                ),
                value: '',
              },
            ]}
            position="right"
          />
        </div>
      ) : undefined}
    </div>
  )
}

export default TopNavigation
