import React, { useState } from 'react'
import { TableComponent } from '../../components/table'
import { format } from 'date-fns'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import Layout from '../../layout'
import { useFetchWaitlistSubscriptionsQuery } from '../../redux-toolkits/waitlist/waitlist.slice'
import { PageHeader } from '../../components/typography/PageHeader'

const WaitListPage: React.FC = (): JSX.Element => {
  const [query, setQuery] = useState({
    page: 1,
    limit: NUMBER_OF_ITEMS_PER_PAGE,
  })

  const { data, isFetching } = useFetchWaitlistSubscriptionsQuery({
    page: query.page,
    limit: query.limit,
  })

  return (
    <Layout>
      <PageHeader title="Waitlist" />

      <TableComponent
        headers={['Name', 'Email', 'Date joined']}
        rows={
          data?.data?.mailList?.length
            ? data.data.mailList.map((waitlistSub) => {
                return {
                  id: waitlistSub._id,
                  content: [
                    <div key="">{waitlistSub.name ?? 'N/A'}</div>,
                    <div key="">{waitlistSub.email}</div>,
                    <div key="">
                      {waitlistSub.createdAt
                        ? format(waitlistSub.createdAt, 'dd-MM-yyyy')
                        : 'N/A'}
                    </div>,
                  ],
                }
              })
            : []
        }
        name="lists-table"
        isRowClickable={false}
        loading={isFetching}
        isEmpty={[undefined, 0].includes(data?.data?.mailList?.length)}
        currentPage={query.page}
        totalDataCount={data?.data?.count ?? 0}
        pageLimit={query.limit}
        pageRangeDisplayed={3}
        onPageChange={(pageNo) => {
          setQuery({ ...query, page: pageNo })
        }}
      />
    </Layout>
  )
}

export default WaitListPage
