import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import { goodOnlineApiSlice } from '../api/apiSlice'
import { WaitlistSubscriptionResponseData } from './waitlist.type'
import { QueryProps } from '../../types/types'

export const waitlistApiSlice = goodOnlineApiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    fetchWaitlistSubscriptions: builder.query<
      WaitlistSubscriptionResponseData,
      QueryProps
    >({
      query: ({ limit = NUMBER_OF_ITEMS_PER_PAGE, page = 1 }) => ({
        url: `contact-us/emails`,
        method: 'GET',
        params: {
          skip: (Number(page) - 1) * Number(limit),
          limit: limit ?? NUMBER_OF_ITEMS_PER_PAGE,
        },
      }),
      transformResponse: (data: WaitlistSubscriptionResponseData) => {
        return data
      },
    }),
  }),
})

export const { useFetchWaitlistSubscriptionsQuery } = waitlistApiSlice
