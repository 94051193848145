import React, { useState } from 'react'
import Layout from '../../layout'
import {
  useGetAllVendorsQuery,
  useInviteVendorMutation,
} from '../../redux-toolkits/vendors/vendors.slice'
import ReactPaginate from 'react-paginate'
import Spinner from '../../components/spinner/Spinner'
import { Plus } from 'lucide-react'
import CountCard from '../orders/CountCard'
import { PageHeader } from '../../components/typography/PageHeader'
import { Button } from '../../components/button'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import { TableComponent } from '../../components/table'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import * as Yup from 'yup'
import { Formik, Field, ErrorMessage, Form } from 'formik'
import { Vendor } from '../../types/types'
import ModalComponent from '../../components/modal'
import { TextInput } from '../../components/inputs/textInput'
import { TabNav } from '../../components/nav/tabNav'
import ActiveVendors from './allVendors'
import AllVendors from './allVendors'

const Vendors: React.FC = () => {
  return (
    <Layout>
      <PageHeader title="Creators" />

      <AllVendors />
    </Layout>
  )
}

export default Vendors
