import React, { useState } from 'react'
import Layout from '../../layout'
import OrderTable from '../orders/OrderTable'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import CountCard from '../orders/CountCard'
import Dropdown from '../../components/inputs/dropdown'
import { Button } from '../../components/button'
import { SearchInput } from '../../components/inputs/searchInput'
import { useParams } from 'react-router-dom'
import { QueryProps } from '../../types/types'
import { Status } from '../../components/cards/statusTag'
import Icon from '../../components/icon'
import { useGetSingleCustomersQuery } from '../../redux-toolkits/user/user.slice'
import Spinner from '../../components/spinner/Spinner'
import { format } from 'date-fns'
import { MiniHeader } from '../../components/typography/miniHeader'
import { ORDERSTATUS } from '../../constants'

export const SingleCustomerPage: React.FC = () => {
  const { id } = useParams()

  const [query, setQuery] = useState<QueryProps & { status: string }>({
    skip: 0,
    limit: 20,
    search: undefined,
    status: 'all',
  })

  const {
    data: customer,
    isFetching,
    isLoading,
  } = useGetSingleCustomersQuery({
    id: id ?? '',
    query,
  })

  return (
    <Layout>
      {isLoading ? (
        <Spinner fullScreen={false} />
      ) : (
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-2 text-sec-black">
            <div
              className="
            w-[80px] h-[80px]
            rounded-full bg-[#4F122A]
            flex items-center justify-center
            text-lg text-white font-medium
            "
            >
              {customer?.data.firstname.charAt(0)}
            </div>
            <p className="text-[40px] text-black font-recoleta-bold">
              {customer?.data.firstname} {customer?.data.lastname}
            </p>
            <p>
              Joined{' '}
              {customer?.data?.createdAt
                ? format(customer?.data.createdAt, 'MMM dd, yyyy')
                : ''}
            </p>
            <div className="flex items-center gap-2">
              <Icon id="envelope" width={24} height={24} />
              <p>{customer?.data.email}</p>
            </div>
            <div className="flex items-center gap-2">
              <Icon id="phone-icon" width={24} height={24} />
              <p>+{customer?.data.phoneNumber}</p>
            </div>
            <div className="flex items-center gap-2">
              <p>Customer&apos;s account status:</p>
              <Status
                text={customer?.data?.deleted ? 'Inactive' : 'Active'}
                type={customer?.data?.deleted ? 'fail' : 'success'}
              />
            </div>
          </div>

          <CountCardContainer className="!my-0">
            <CountCard
              count={customer?.data.orderCount ?? 0}
              text="Total orders"
              icon=""
              isCurrency={false}
            />
            <CountCard
              count={customer?.data.totalSpend ?? 0}
              text="Total spend"
              icon=""
              isCurrency={true}
            />
          </CountCardContainer>

          <div className="flex flex-col gap-6">
            <MiniHeader name="Order History" />

            <div className="flex flex-wrap items-center justify-between gap-4">
              <div className="!w-full md:!max-w-[400px]">
                <SearchInput
                  searchFunction={(search) => {
                    setQuery({
                      ...query,
                      search: search === '' ? undefined : search,
                      skip: 0,
                    })
                  }}
                  placeholder="Search"
                />
              </div>

              <Dropdown
                menuClassName=""
                menuButton={
                  <Button
                    label={`Filter: ${query.status
                      .split('_')
                      .join(' ')
                      .charAt(0)}${query.status
                      .split('_')
                      .join(' ')
                      .slice(1)
                      .toLowerCase()}`}
                    primary={false}
                    icon="filter"
                    className="!py-3 !px-4 !text-base !font-normal !w-full md:max-w-fit"
                  />
                }
                onClickMenuItem={(selected) => {
                  setQuery({
                    ...query,
                    page: 1,
                    skip: 0,
                    status: selected.value as string,
                  })
                }}
                menuItems={[
                  { name: 'All', value: 'all' },
                  ...ORDERSTATUS.map((status) => {
                    const name = status.name.split('_').join(' ')
                    return {
                      name: `${name.charAt(0)}${name.slice(1).toLowerCase()}`,
                      value: status.name,
                    }
                  }),
                ]}
              />
            </div>

            <OrderTable
              orders={
                customer?.data?.orders?.map((order) => {
                  return {
                    order: order,
                    orderItems: [],
                  }
                }) ?? []
              }
              currentPage={1}
              totalDataCount={0}
              pageLimit={Number(query.limit)}
              onPageChange={() => {}}
              loading={isFetching}
              showCustomerName={false}
              name="Order History"
              showName={false}
            />
          </div>
        </div>
      )}
    </Layout>
  )
}
