import React, { useState } from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import { CreateCategoryPayload } from '../../../redux-toolkits/products/products.type'
import {
  addLocationManualSchema,
  addPickUpLocationManualSchema,
  CreateCategorySchema,
} from '../../../validation/validate'
import {
  useCreateProductCategoryMutation,
  useEditProductCategoryMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import AddressInput from '../../inputs/addressInput'
import {
  useAddAvailableLocationsMutation,
  useAddPickupLocationMutation,
} from '../../../redux-toolkits/user/user.slice'
import { AddAvailableLocationsPayload } from '../../../redux-toolkits/user/user.type'
import { TabNav } from '../../nav/tabNav'

export const AddLocationModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: () => void
  type?: 'available' | 'pickup'
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = () => {},
  type = 'available',
}) => {
  const [selectedLocation, setSelectedLocation] =
    useState<AddAvailableLocationsPayload>()

  const [activeTab, setActiveTab] = useState('Search')

  const [addLocation, { isLoading }] = useAddAvailableLocationsMutation()
  const [addPickUpLocation, { isLoading: addingPickUp }] =
    useAddPickupLocationMutation()

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      name: '',
      city: '',
      state: '',
      long: '',
      lat: '',
      address: '',
    },
    validationSchema:
      type === 'available'
        ? addLocationManualSchema
        : addPickUpLocationManualSchema,
    onSubmit: (vals) => {
      if (type === 'available') {
        const payload = {
          isAvailable: true,
          city: vals.city,
          state: vals.state,
          name: vals.name,
          country: 'Nigeria',
          coordinates: [Number(vals.long), Number(vals.lat)],
        }

        addLocation(payload)?.then((resp) => {
          if (resp.data?.success) {
            resetForm()
            onSuccessCallback()
          }
        })
      } else {
        const payload = {
          name: vals.name,
          address: vals.address,
          isAvailable: true,
        }

        addPickUpLocation(payload)?.then((resp) => {
          if (resp.data?.success) {
            resetForm()
            onSuccessCallback()
          }
        })
      }
    },
  })

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={`Add new location`}
    >
      <TabNav
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={[
          { name: 'Location search', id: 'Search' },
          { name: 'Manual', id: 'Manual' },
        ]}
        className="mb-10"
      />

      {activeTab === 'Search' && (
        <>
          <AddressInput
            onSelect={(selected) => {
              setSelectedLocation({
                isAvailable: true,
                city: selected.city,
                state: selected.state,
                name: selected.address,
                country: selected.country,
                coordinates: [selected.lng, selected.lat],
              })
            }}
            className="!rounded-lg"
          />

          <Button
            label="Proceed"
            type="button"
            className="mt-20 w-full"
            disabled={isLoading || !selectedLocation || addingPickUp}
            loading={isLoading || addingPickUp}
            primary
          />
        </>
      )}

      {activeTab === 'Manual' && (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <TextInput
            type="text"
            name="name"
            placeholder="Location name"
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
            errors={errors.name && touched.name ? errors.name : ''}
            hasIcon={false}
            className="!border-divider-gray"
          />

          {type === 'pickup' && (
            <TextInput
              type="text"
              name="address"
              placeholder="Location address"
              value={values.address}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.address && touched.address ? errors.address : ''}
              hasIcon={false}
              className="!border-divider-gray"
            />
          )}

          {type === 'available' && (
            <TextInput
              type="text"
              name="city"
              placeholder="City"
              value={values.city}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.city && touched.city ? errors.city : ''}
              hasIcon={false}
              className="!border-divider-gray"
            />
          )}

          {type === 'available' && (
            <TextInput
              type="text"
              name="state"
              placeholder="State"
              value={values.state}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.state && touched.state ? errors.state : ''}
              hasIcon={false}
              className="!border-divider-gray"
            />
          )}

          {type === 'available' && (
            <div className="grid grid-cols-2 gap-4">
              <TextInput
                type="text"
                name="long"
                placeholder="Longitude"
                value={values.long}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors.long && touched.long ? errors.long : ''}
                hasIcon={false}
                className="!border-divider-gray"
              />

              <TextInput
                type="text"
                name="lat"
                placeholder="Latitude"
                value={values.lat}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors.lat && touched.lat ? errors.lat : ''}
                hasIcon={false}
                className="!border-divider-gray"
              />
            </div>
          )}

          <Button
            label="Proceed"
            type="submit"
            className="mt-10 w-full"
            disabled={isLoading || !isValid || addingPickUp}
            loading={isLoading || addingPickUp}
            primary
          />
        </form>
      )}
    </ModalComponent>
  )
}
