import React from 'react'
import './PageNotFound.css'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/button'

export const PageNotFound: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div
      className="
      flex flex-col 
      justify-center items-center 
      h-screen
      gap-4 p-5
      "
    >
      <img src="/assets/images/404.svg" alt="404" />

      <div className="flex flex-col items-center justify-center text-center gap-2">
        <p className="text-2xl font-bold">Page not found</p>
        <p></p>
      </div>

      <Button
        type="button"
        label="Go home"
        className="w-full max-w-[300px]"
        primary={false}
        onClick={() => {
          navigate('/dashboard')
        }}
      />
    </div>
  )
}
