import React, { useState, useRef } from 'react'
import { toast } from 'react-toastify'
import Icon from '../icon'
import { convertSize } from '../../helpers'
import { ErrorText } from '../typography/ErrorText'

interface FileUploadInputProps {
  maxNumberOfFiles?: number
  handleChange: (files: FileList) => void
  selectedFiles: File[]
  setSelectedFiles: (files: File[]) => void
  types?: string
  accept?: string
  title?: string
  errors?: string
}

export const FileUploadInput: React.FC<FileUploadInputProps> = ({
  maxNumberOfFiles = 1,
  handleChange,
  selectedFiles,
  setSelectedFiles,
  types = 'PNG, JPG',
  accept = 'image/*',
  title = 'a file',
  errors,
}) => {
  const [dragActive, setDragActive] = useState(false)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileUpload = (files: FileList) => {
    if (files.length > maxNumberOfFiles) {
      toast.warning(
        `You can only upload a maximum of ${maxNumberOfFiles} files(s)`,
      )
    } else {
      handleChange(files)
    }
  }

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e?.dataTransfer?.files && e?.dataTransfer?.files[0]) {
      handleFileUpload(e?.dataTransfer?.files)
    }
  }

  return (
    <div className="relative overflow-hidden">
      <div
        onClick={() => {
          if (!selectedFiles || selectedFiles?.length < 1) {
            fileInputRef?.current?.click()
          }
        }}
        onDragEnter={handleDrag}
      >
        {selectedFiles && selectedFiles?.length > 0 ? (
          <div className="py-4 flex flex-col gap-4">
            {selectedFiles.map((file, i) => {
              const keyValue = `selectedFile${i}`
              return (
                <div
                  key={keyValue}
                  className="flex 
                                items-center justify-between gap-4"
                >
                  <div
                    className="
                                    flex
                                    items-center 
                                    gap-3
                                    "
                  >
                    <div
                      className="w-fit p-[8px] 
                                        text-pry-green-200 rounded-full
                                        bg-light-green-bg
                                        "
                    >
                      <Icon id="small-document-icon" width={16} height={16} />
                    </div>
                    <div>
                      <div className="text-base max-w-[227px] text-ellipsis whitespace-nowrap overflow-hidden">
                        {file?.name}
                      </div>
                      <div className="text-sm mt-2 text-pry-dark-green-300/60">
                        {convertSize(file?.size)} • 100% uploaded
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-fit p-[8px] 
                                        text-danger rounded-full
                                        hover:!cursor-pointer
                                        "
                    onClick={() => {
                      setSelectedFiles(
                        selectedFiles
                          .slice(0, i)
                          .concat(selectedFiles.slice(i + 1)),
                      )
                    }}
                  >
                    <Icon id="delete-icon" width={20} height={20} />
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div
            className={`
                        hover:!cursor-pointer
                        p-4
                        border border-transparent 
                        ${dragActive && '!border-primary !border-dotted'}
                        rounded-[8px]
                        bg-primary/5
                        flex flex-col
                        justify-center items-center
                        gap-3
                        `}
          >
            <Icon
              id="gallery-icon"
              width={32}
              height={32}
              className="text-pry-green-200"
            />
            <div
              className="
                        flex flex-col
                        justify-center items-center
                        gap-1
                        "
            >
              <div className="text-base text-pry-dark-green-300/80 text-center">
                <span className="text-pry-green-200">Upload {title} </span>or
                drag and drop
              </div>
              <div className="text-sm text-pry-dark-green-300/80">
                {types} up to 5MB
              </div>
            </div>
          </div>
        )}
      </div>
      <input
        type="file"
        className="hidden"
        accept={accept}
        ref={fileInputRef}
        multiple={maxNumberOfFiles > 1}
        onChange={(e) => {
          if (e?.target?.files && e?.target?.files[0]) {
            handleFileUpload(e?.target?.files)
          }
        }}
      />
      {dragActive && (
        <div
          className="absolute inset-0 z-10 opacity-0"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}

      {errors && <ErrorText text={errors} />}
    </div>
  )
}
