import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import Spinner from '../../components/spinner/Spinner'
import {
  useGetOrdersQuery,
  useGetOrdersMetricsCountQuery,
  useGetOrdersMetricsValueQuery,
} from '../../redux-toolkits/orders/orders.slice'
import OrderTable from './OrderTable'
import { NUMBER_OF_ITEMS_PER_PAGE, ORDERSTATUS } from '../../constants'
import CountCard from './CountCard'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import { PageHeader } from '../../components/typography/PageHeader'
import { TextInput } from '../../components/inputs/textInput'
import Dropdown from '../../components/inputs/dropdown'
import { Button } from '../../components/button'
import { updateNewOrderStatus } from '../../redux-toolkits/orders/order.reducer'
import { useAppDispatch } from '../../hooks'
import { SearchInput } from '../../components/inputs/searchInput'

export const OrdersPage: React.FC = () => {
  const [status, setStatus] = useState<string>('all')
  const [startDate, setStartDate] = useState<string>()
  const [search, setSearch] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const [page, setPage] = useState<number | string>(1)

  const { data: counts, isFetching: fetchingCountsMetrics } =
    useGetOrdersMetricsCountQuery({ startDate, endDate })

  const { data: values, isFetching: fetchingValuesMetrics } =
    useGetOrdersMetricsValueQuery({ startDate, endDate })

  const {
    data: payload,
    isFetching,
    isError,
    isSuccess,
  } = useGetOrdersQuery({
    page: Number(page),
    limit: NUMBER_OF_ITEMS_PER_PAGE,
    status: status === 'all' || status === '' ? undefined : status,
    startDate,
    endDate,
    search: search !== '' ? search : undefined,
  })

  const value = values?.data
  const count = counts?.data

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isFetching && isSuccess) {
      dispatch(updateNewOrderStatus(false))
    }
  }, [isFetching, isSuccess])

  if (isError) {
    return <p>Oops! something went wrong.</p>
  }

  return (
    <Layout>
      {fetchingCountsMetrics || fetchingValuesMetrics ? (
        <Spinner fullScreen={false} />
      ) : (
        <>
          <PageHeader title={`Orders`} className="capitalize" />

          <div className="flex items-center justify-end gap-4">
            <div className="flex flex-col gap-2">
              <p className="text-sm text-sec-black">Start Date:</p>
              <div>
                <TextInput
                  placeholder="Start date"
                  type="date"
                  name="startDate"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value)
                  }}
                  hasIcon={false}
                  readOnly={false}
                  className="!border-black !w-full md:max-w-fit !py-3"
                />
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <p className="text-sm text-sec-black">End Date:</p>
              <div>
                <TextInput
                  placeholder="End date"
                  type="date"
                  name="endDate"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value)
                  }}
                  hasIcon={false}
                  readOnly={startDate === '' || startDate === undefined}
                  className="!border-black !w-full md:max-w-fit !py-3"
                />
              </div>
            </div>
          </div>

          <CountCardContainer>
            <CountCard
              count={value?.totalOrderValue ?? 0}
              text="Total order"
              icon=""
              isCurrency={true}
            />
            <CountCard
              count={value?.completedOrderValue ?? 0}
              text="Completed order"
              icon=""
              isCurrency={true}
            />
            <CountCard
              count={value?.pendingOrderValue ?? 0}
              text="Pending order"
              icon=""
              isCurrency={true}
            />
            <CountCard
              count={value?.processingOrderValue ?? 0}
              text="Processing order"
              icon=""
              isCurrency={true}
            />
            <CountCard
              count={value?.cancelledOrderValue ?? 0}
              text="Cancelled order"
              icon=""
              isCurrency={true}
            />

            <CountCard
              count={count?.totalOrderCount ?? 0}
              text="Total order count"
              icon=""
              isCurrency={false}
            />
            <CountCard
              count={count?.pendingOrderCount ?? 0}
              text="Pending order count"
              icon=""
              isCurrency={false}
            />
            <CountCard
              count={count?.processingOrderCount ?? 0}
              text="Processing order count"
              icon=""
              isCurrency={false}
            />
            <CountCard
              count={count?.completedOrderCount ?? 0}
              text="Completed order count"
              icon=""
              isCurrency={false}
            />
            <CountCard
              count={count?.cancelledOrderCount ?? 0}
              text="Cancelled order count"
              icon=""
              isCurrency={false}
            />
          </CountCardContainer>

          <div className="flex flex-wrap items-center justify-between gap-4 mb-10">
            <div className="!w-full md:!max-w-[400px]">
              <SearchInput
                searchFunction={(search) => {
                  setSearch(search)
                  setPage(1)
                }}
                placeholder="Search"
              />
            </div>

            <Dropdown
              menuClassName=""
              menuButton={
                <Button
                  label={`Filter: ${status
                    .split('_')
                    .join(' ')
                    .charAt(0)}${status
                    .split('_')
                    .join(' ')
                    .slice(1)
                    .toLowerCase()}`}
                  primary={false}
                  icon="filter"
                  className="!py-3 !px-4 !text-base !font-normal !w-full md:max-w-fit"
                />
              }
              onClickMenuItem={(selected) => {
                setStatus(selected.value as string)
                setPage(1)
              }}
              menuItems={[
                { name: 'All', value: 'all' },
                ...ORDERSTATUS.map((status) => {
                  const name = status.name.split('_').join(' ')
                  return {
                    name: `${name.charAt(0)}${name.slice(1).toLowerCase()}`,
                    value: status.name,
                  }
                }),
              ]}
            />
          </div>

          <OrderTable
            orders={payload?.data?.orders ?? []}
            currentPage={Number(page)}
            totalDataCount={payload?.data?.pagination?.count ?? 0}
            pageLimit={NUMBER_OF_ITEMS_PER_PAGE}
            onPageChange={(page) => {
              setPage(page)
            }}
            loading={isFetching}
          />
        </>
      )}
    </Layout>
  )
}
