import React from 'react'
import './button.css'
import Icon from '../icon'
import { LoadingOval } from '../spinner/Spinner'

export interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean
  /**
   * What background color to use
   */
  backgroundColor?: string
  /**
   * Button contents
   */
  label?: string
  /**
   * Optional click handler
   */
  onClick?: () => void
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  className?: string
  icon?: string
  iconSize?: number
  loading?: boolean
  loaderColor?: string
  size?: 'medium' | 'large' | 'small'
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  primary = false,
  backgroundColor,
  label,
  disabled,
  type,
  className,
  loading = false,
  loaderColor = 'black',
  icon,
  iconSize = 16,
  size = 'large',
  ...props
}: ButtonProps): JSX.Element => {
  const mode = primary
    ? 'bg-black text-white border-black'
    : 'bg-transparent text-black border-black'
  return (
    <button
      disabled={disabled}
      type={type ?? 'button'}
      className={`
        relative
        border-[1.5px]
        rounded-[8px]
        ${mode}
        hover:scale-[0.98] transition-all duration-300
        disabled:scale-100 disabled:cursor-not-allowed
        disabled:!bg-[#d3d3d3] disabled:!border-[#cccccc]
        disabled:!text-[#a9a9a9]
        ${size === 'large' && 'py-3 md:py-[14px] px-3'}
        ${size === 'medium' && '!py-3 !px-6'}
        ${size === 'small' && '!py-2 !px-3 text-xs !gap-2'}
        text-sm font-medium
        flex items-center gap-4 justify-center
        ${className}
        `}
      style={{ backgroundColor }}
      {...props}
    >
      {icon && <Icon id={icon} width={iconSize} height={iconSize} />}
      {label && <span className={`${loading && 'opacity-0'}`}>{label}</span>}
      {loading && (
        <span className="absolute inset-0 flex items-center justify-center">
          <LoadingOval loaderHeight="25" loaderWidth="25" color={loaderColor} />
        </span>
      )}
    </button>
  )
}
