import React from 'react'

export const MiniHeader = ({
  name,
  className = '',
}: {
  name: string
  className?: string
}) => {
  return <p className={`text-xl font-semibold ${className}`}>{name}</p>
}
