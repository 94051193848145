import React, { useState } from 'react'
import { TableComponent } from '../../../components/table'
import { useNavigate } from 'react-router'
import {
  useFetchListsQuery,
  useMoveListMutation,
  usePinUnpinListMutation,
  usePublishUnpublishListMutation,
} from '../../../redux-toolkits/lists/list.slice'
import { format } from 'date-fns'
import {
  MAX_NO_PUBLISHED_LISTS,
  NUMBER_OF_ITEMS_PER_PAGE,
} from '../../../constants'
import clsx from 'clsx'
import { SingleListDoc } from '../../../redux-toolkits/lists/list.types'
import { Button } from '../../../components/button'
import Dropdown from '../../../components/inputs/dropdown'
import Icon from '../../../components/icon'
import ModalComponent from '../../../components/modal'
import { NavLink } from 'react-router-dom'
import { Status } from '../../../components/cards/statusTag'
import { LoadingOval } from '../../../components/spinner/Spinner'
import { useModalControl } from '../../../hooks/useModalControl'

const CuratedListsTable: React.FC = () => {
  const navigate = useNavigate()

  const [customIndex, setCustomIndex] = useState<number>()
  const [editing, setEditing] = useState<SingleListDoc>()
  const [query, setQuery] = useState({
    page: 1,
    limit: NUMBER_OF_ITEMS_PER_PAGE,
  })

  const { modalOpen, handleModalClose, handleModalOpen, setModalOpen, modal } =
    useModalControl()

  const { data, isFetching, refetch } = useFetchListsQuery({
    page: query.page,
    limit: query.limit,
  })

  const [moveList, { isLoading: movingList }] = useMoveListMutation()

  return (
    <div>
      <div className="flex justify-end mb-10">
        <Button
          type="button"
          primary
          size="medium"
          onClick={() => {
            navigate('/lists/curated/new')
          }}
          label="Create list"
          icon="plus"
        />
      </div>

      <TableComponent
        headers={[
          'Title',
          'Category',
          'Status',
          'Date created',
          'Author',
          movingList ? (
            <LoadingOval loaderHeight="25" loaderWidth="25" color="black" />
          ) : (
            ' '
          ),
        ]}
        rows={
          data?.data.docs.map((listDetail, idx) => {
            return {
              id: listDetail._id,
              content: [
                <div key="" className="flex itemx-center gap-2">
                  {listDetail.pinned && (
                    <Icon
                      id="pin"
                      width={20}
                      height={20}
                      className="text-primary"
                    />
                  )}
                  <NavLink
                    to={`/lists/curated/view/${listDetail._id}`}
                    className={`
                      hover:text-black hover:underline
                      transition-all duration-500
                      `}
                  >
                    {listDetail.title}
                  </NavLink>
                </div>,
                <div key="">{listDetail?.category?.name ?? ''}</div>,
                <div key="">
                  <Status
                    type={
                      listDetail.status.toLocaleLowerCase() === 'published'
                        ? 'success'
                        : 'warn'
                    }
                    text={listDetail.status.toLowerCase()}
                  />
                </div>,
                <div key="">
                  {format(listDetail.createdAt, 'dd/MM/yyyy h:mma')}
                </div>,
                <div key="">{`${listDetail.author.firstname} ${listDetail.author.lastname}`}</div>,
                <Dropdown
                  key={`${listDetail._id}-controls`}
                  menuButton={
                    <Icon id="ellipses" height={18} width={18} className="" />
                  }
                  onClickMenuItem={() => {}}
                  menuItems={[
                    {
                      name: (
                        <button
                          className="disabled:opacity-30 w-full text-left"
                          onClick={(e): any => {
                            setEditing(listDetail)
                            handleModalOpen('publishListModal')
                          }}
                        >
                          {listDetail.status === 'DRAFT'
                            ? 'Publish list'
                            : 'Unpublish list'}
                        </button>
                      ),
                      value: '',
                    },
                    {
                      name: (
                        <button
                          className="disabled:opacity-30 w-full text-left"
                          onClick={(e): any => {
                            setEditing(listDetail)
                            handleModalOpen('pinListModal')
                          }}
                        >
                          {listDetail.pinned ? 'Unpin list' : 'Pin list'}
                        </button>
                      ),
                      value: '',
                    },
                    listDetail.position <= 0 || listDetail.pinned
                      ? undefined
                      : {
                          name: (
                            <button
                              type="button"
                              onClick={() => {
                                moveList({
                                  id: listDetail._id,
                                  categoryId: listDetail.category._id,
                                  newPosition: listDetail.position - 1,
                                })?.then((resp) => {
                                  if (resp.data?.success) {
                                    refetch()
                                  }
                                })
                              }}
                              className={`
                            w-full text-left
                            `}
                              disabled={listDetail.position <= 0 || movingList}
                            >
                              Move up
                            </button>
                          ),
                          value: '',
                        },
                    listDetail.position >= MAX_NO_PUBLISHED_LISTS ||
                    listDetail.position === data.data.total - 1 ||
                    listDetail.pinned
                      ? undefined
                      : {
                          name: (
                            <button
                              type="button"
                              onClick={() => {
                                moveList({
                                  id: listDetail._id,
                                  categoryId: listDetail.category._id,
                                  newPosition: listDetail.position + 1,
                                })?.then((resp) => {
                                  if (resp.data?.success) {
                                    refetch()
                                  }
                                })
                              }}
                              className={`
                            w-full text-left
                            `}
                              disabled={
                                listDetail.position >= MAX_NO_PUBLISHED_LISTS ||
                                listDetail.position === data.data.total - 1 ||
                                movingList
                              }
                            >
                              Move down
                            </button>
                          ),
                          value: '',
                        },
                    listDetail.pinned
                      ? undefined
                      : {
                          name: (
                            <div
                              className={`
                              w-full text-left
                              flex gap-2 items-center
                              justify-between
                            `}
                            >
                              <div className="flex items-center gap-2">
                                Move to
                                <input
                                  type="number"
                                  value={customIndex ?? listDetail.position + 1}
                                  onChange={(e) => {
                                    setCustomIndex(Number(e.target.value))
                                  }}
                                  className="
                              max-w-[50px] text-center 
                              p-1 bg-bg-gray
                              rounded-md
                              ring-0 outline-0
                              "
                                  onClick={(e) => {
                                    e.stopPropagation()
                                  }}
                                  min={1}
                                  max={data.data.total}
                                />
                              </div>
                              <button
                                onClick={() => {
                                  customIndex &&
                                    moveList({
                                      id: listDetail._id,
                                      categoryId: listDetail.category._id,
                                      newPosition: customIndex - 1,
                                    })?.then((resp) => {
                                      if (resp.data?.success) {
                                        refetch()
                                      }
                                    })
                                }}
                                className="text-white bg-black p-2 rounded-full flex items-center justify-center"
                              >
                                <Icon
                                  id="left-caret"
                                  width={10}
                                  height={10}
                                  className="rotate-180"
                                />
                              </button>
                            </div>
                          ),
                          value: '',
                        },
                  ]}
                />,
              ],
            }
          }) ?? []
        }
        name="lists-table"
        loading={isFetching}
        isEmpty={[undefined, 0].includes(data?.data?.docs?.length)}
        currentPage={query.page}
        totalDataCount={data?.data?.total}
        pageLimit={query.limit}
        pageRangeDisplayed={3}
        onPageChange={(pageNo) => {
          setQuery({ ...query, page: pageNo })
        }}
      />

      <PinListModal
        open={modalOpen && modal === 'pinListModal'}
        closeModal={() => handleModalClose()}
        list={editing as SingleListDoc}
        successCallback={() => {
          refetch()
        }}
      />

      <PublishListModal
        open={modalOpen && modal === 'publishListModal'}
        closeModal={() => handleModalClose()}
        list={editing as SingleListDoc}
        successCallback={() => {
          refetch()
        }}
      />
    </div>
  )
}

const PinListModal: React.FC<{
  open: boolean
  closeModal: () => void
  list: SingleListDoc
  successCallback: () => void
}> = ({ open, closeModal, list, successCallback }) => {
  const [pinUnpinList, { isLoading }] = usePinUnpinListMutation()

  return (
    <>
      <ModalComponent
        open={open}
        setOpen={() => {}}
        onCloseCallback={() => {
          closeModal()
        }}
        title={`${list?.pinned ? 'Unpin' : 'Pin'} "${list?.title}"`}
      >
        <span className="text-lg font-normal">Confirm to proceed</span>

        <div className="grid grid-cols-2 gap-4 mt-20">
          <Button label={'Cancel'} onClick={closeModal} />
          <Button
            label={'Confirm'}
            primary
            onClick={async () => {
              await pinUnpinList({
                body: { pinned: String(!list.pinned) },
                id: list._id,
              }).then((resp) => {
                if (resp.data?.success) {
                  successCallback()
                }
              })
              closeModal()
            }}
            loading={isLoading}
            disabled={isLoading}
          />
        </div>
      </ModalComponent>
    </>
  )
}

const PublishListModal: React.FC<{
  open: boolean
  closeModal: () => void
  list: SingleListDoc
  successCallback: () => void
}> = ({ open, closeModal, list, successCallback }) => {
  const [publishUnpublishList, { isLoading }] =
    usePublishUnpublishListMutation()

  return (
    <>
      <ModalComponent
        open={open}
        setOpen={() => {}}
        onCloseCallback={() => {
          closeModal()
        }}
        title={`${
          list?.status === 'DRAFT' ? 'Publish' : 'Unpublish'
        } "${list?.title}"`}
      >
        <span className="text-lg font-normal">Confirm to proceed</span>

        <div className="grid grid-cols-2 gap-4 mt-20">
          <Button label={'Cancel'} onClick={closeModal} />
          <Button
            label={'Confirm'}
            primary
            onClick={async () => {
              await publishUnpublishList({
                categoryId: list.category._id,
                id: list._id,
                type: list?.status === 'DRAFT' ? 'publish' : 'unpublish',
              }).then((resp) => {
                if (resp.data?.success) {
                  successCallback()
                }
              })
              closeModal()
            }}
            loading={isLoading}
            disabled={isLoading}
          />
        </div>
      </ModalComponent>
    </>
  )
}

export default React.memo(CuratedListsTable)
