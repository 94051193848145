import React from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import {
  CreateCategorySchema,
  CreateSubCategorySchema,
} from '../../../validation/validate'
import {
  useCreateProductCategoryMutation,
  useCreateSubCategoryMutation,
  useEditProductCategoryMutation,
  useEditSubCategoryMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import { SingleSubCategory } from '../../../redux-toolkits/products/products.type'
import Dropdown from '../../inputs/dropdown'
import Icon from '../../icon'
import { useFetchListCategoriesQuery } from '../../../redux-toolkits/lists/list.slice'

const statuses = [
  { text: 'inactive', value: false },
  { text: 'active', value: true },
]

export const AddSubCategoryModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: () => void
  mode: 'edit' | 'create'
  subCategory?: SingleSubCategory
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = () => {},
  mode,
  subCategory,
}) => {
  const [createSubCategory, { isLoading: creating }] =
    useCreateSubCategoryMutation()

  const [editSubCategory, { isLoading: editing }] = useEditSubCategoryMutation()

  const { isLoading: loadingCategories, data: categories } =
    useFetchListCategoriesQuery({
      skip: 0,
      limit: 100,
    })

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: subCategory?.name ?? '',
      categoryId: subCategory?.category?._id ?? '',
      category: subCategory?.category ?? undefined,
      isActive: subCategory?.isActive ?? false,
    },
    enableReinitialize: true,
    validationSchema: CreateSubCategorySchema,
    onSubmit: (values) => {
      const payload = {
        name: values.name,
        categoryId: values.categoryId,
        isActive: values.isActive,
      }

      if (mode === 'create') {
        createSubCategory(payload).then((resp) => {
          if (resp.data?.success) {
            resetForm()
            setOpen(false)
            onSuccessCallback()
          }
        })
      } else if (mode === 'edit' && subCategory) {
        editSubCategory({ id: subCategory._id, payload: payload }).then(
          (resp) => {
            if (resp.data?.success) {
              resetForm()
              setOpen(false)
              onSuccessCallback()
            }
          },
        )
      }
    },
  })

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={mode === 'create' ? 'Add new sub-category' : 'Edit sub-category'}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <TextInput
          type="text"
          name="name"
          placeholder="Category name"
          value={values.name}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={errors.name && touched.name ? errors.name : ''}
          hasIcon={false}
          className="!border-divider-gray"
        />

        <Dropdown
          loading={loadingCategories}
          className=""
          menuClassName="max-h-[200px]"
          widthClass="!w-full"
          menuButton={
            <TextInput
              type="text"
              name="category"
              placeholder="Product category"
              value={values?.category?.name ?? ''}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={
                errors.categoryId && touched.categoryId ? errors.categoryId : ''
              }
              hasIcon={false}
              readOnly
              extra={
                <Icon
                  id={'left-caret'}
                  width={16}
                  height={16}
                  className="-rotate-90 text-sec-black"
                />
              }
              className="!border-divider-gray"
            />
          }
          onClickMenuItem={(selection) => {
            const selected: { name: string; value: ListCategoryDoc } = {
              name: selection.name as string,
              value: selection.value as ListCategoryDoc,
            }

            setFieldValue('category', selected.value)

            setFieldValue('categoryId', selected.value._id)
          }}
          menuItems={categories?.data.data.map((category) => {
            return {
              name: category.name,
              value: category,
            }
          })}
        />

        <Dropdown
          menuButton={
            <TextInput
              type="text"
              name="status"
              placeholder="Select status"
              value={values.isActive ? 'Active' : 'Inactive'}
              onBlur={handleBlur}
              onChange={handleChange}
              hasIcon={false}
              readOnly
              extra={
                <Icon
                  id={'left-caret'}
                  width={16}
                  height={16}
                  className="-rotate-90 text-sec-black"
                />
              }
            />
          }
          onClickMenuItem={(selection) => {
            setFieldValue('isActive', selection.value)
          }}
          menuItems={statuses.map((status) => {
            return {
              name: status.text,
              value: status.value,
            }
          })}
        />

        <Button
          label="Proceed"
          type="submit"
          className="mt-10"
          disabled={!isValid || creating || editing}
          loading={creating || editing}
          primary
        />
      </form>
    </ModalComponent>
  )
}
