import React, { useEffect, useState } from 'react'
import ModalComponent from '..'
import { Button } from '../../button'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../../constants'
import { useLazyGetAllVendorsQuery } from '../../../redux-toolkits/vendors/vendors.slice'
import { debounce } from 'lodash'
import { FileUploadInput } from '../../inputs/fileUpload'
import Dropdown from '../../inputs/dropdown'
import { TextInput } from '../../inputs/textInput'
import Icon from '../../icon'
import { useFormik } from 'formik'
import { BulkUploadProductsSchema } from '../../../validation/validate'
import { useBulkUploadProductsMutation } from '../../../redux-toolkits/products/products.slice'

const handleSearch = (searchFunction: (val?: any) => void, query?: any) => {
  searchFunction(query)
}

const handleDebouncedSearch = debounce(handleSearch, 800)

export const BulkUploadProductsModal = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccess = () => {},
}: {
  open: boolean
  setOpen: (val: boolean) => void
  onCloseCallback?: () => void
  onSuccess?: () => void
}) => {
  const [vendorSearch, setVendorSearch] = useState<string>()

  const [getAllVendors, { data: vendors, isFetching: fetchingVendors }] =
    useLazyGetAllVendorsQuery()

  const [doBulkUpload, { isLoading }] = useBulkUploadProductsMutation()

  const {
    touched,
    handleBlur,
    setFieldValue,
    errors,
    values,
    isValid,
    handleSubmit,
  } = useFormik({
    initialValues: {
      vendor: { _id: '', name: '' },
      file: [],
    },
    validationSchema: BulkUploadProductsSchema,
    onSubmit: async (vals) => {
      const formData = new FormData()

      formData.append('vendorId', vals.vendor._id)
      if ((vals.file as File[])[0].type.includes('csv')) {
        formData.append('itemsCsv', vals.file[0])
      } else {
        formData.append('itemsXlsx', vals.file[0])
      }

      const resp = await doBulkUpload(formData)

      if (!resp.error) {
        setOpen(false)
        onCloseCallback()
        onSuccess()
      }
    },
  })

  useEffect(() => {
    handleDebouncedSearch(getAllVendors, {
      page: `1`,
      limit: NUMBER_OF_ITEMS_PER_PAGE.toString(),
      search: vendorSearch,
    })
  }, [vendorSearch])
  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={onCloseCallback}
      title={'Bulk Upload Products'}
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <Dropdown
          loading={fetchingVendors}
          className=""
          menuClassName="max-h-[200px]"
          widthClass="!w-full"
          menuButton={
            <TextInput
              type="text"
              name="vendor"
              placeholder="Vendor"
              value={vendorSearch ? vendorSearch : values.vendor.name}
              onBlur={handleBlur}
              onChange={(e) => {
                setVendorSearch(e.target.value)
              }}
              errors={
                errors.vendor?._id && touched.vendor?._id
                  ? errors.vendor._id
                  : ''
              }
              hasIcon={false}
              readOnly={false}
              extra={
                <Icon
                  id={'left-caret'}
                  width={16}
                  height={16}
                  className="-rotate-90 text-sec-black"
                />
              }
              className="!border-divider-gray"
            />
          }
          onClickMenuItem={(selection) => {
            setFieldValue('vendor', {
              _id: selection.value,
              name: selection.name,
            })
            setVendorSearch(undefined)
          }}
          menuItems={vendors?.data.vendors.map((vendor) => {
            return {
              name: vendor.businessName,
              value: vendor._id,
            }
          })}
        />

        <FileUploadInput
          maxNumberOfFiles={1}
          handleChange={(files) => {
            setFieldValue('file', Object.values(files))
          }}
          selectedFiles={
            (values.file as File[])?.length > 0 ? (values?.file as File[]) : []
          }
          setSelectedFiles={(data) => {
            setFieldValue('file', data)
          }}
          errors={errors?.file ? (errors?.file as string) : ''}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          types="CSV, XLSX"
        />

        <Button
          type="submit"
          label="Upload"
          loading={isLoading}
          disabled={!isValid || isLoading}
          onClick={() => {}}
          className=""
          primary
        />
      </form>
    </ModalComponent>
  )
}
