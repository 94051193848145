import React from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import {
  CreateCategoryPayload,
  Tag,
} from '../../../redux-toolkits/products/products.type'
import {
  AddComboItemSchema,
  AddProductVariantSchema,
  CreateCategorySchema,
  CreateTagSchema,
} from '../../../validation/validate'
import {
  useCreateProductCategoryMutation,
  useCreateProductTagMutation,
  useEditProductCategoryMutation,
  useEditProductTagMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import {
  ComboItem,
  CreatedProductVariant,
  ProductVariant,
  SingleVariant,
} from '../../../types/types'
import { Checkbox } from '../../inputs/Checkbox'
import Dropdown from '../../inputs/dropdown'
import Icon from '../../icon'

export const AddProductVariantModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: (val: CreatedProductVariant) => void
  mode: 'edit' | 'create'
  variant?: CreatedProductVariant
  loading?: boolean
  variants: SingleVariant[]
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = (val) => {},
  mode,
  variant,
  loading,
  variants,
}) => {
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      variant: variant?.variant._id ?? '',
      variantName: variant?.variant.name ?? '',
      value: variant?.value ?? '',
      price: variant?.price ?? '',
    },
    enableReinitialize: true,
    validationSchema: AddProductVariantSchema,
    onSubmit: (values, { resetForm }) => {
      const itemToAdd: CreatedProductVariant = {
        variant: {
          _id: values.variant,
          name: values.variantName,
        },
        value: values.value,
        price: Number(values.price),
        _id: values.variant,
      }

      onSuccessCallback(itemToAdd)
      resetForm()
    },
  })
  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={
        mode === 'create' ? 'Add new product variant' : 'Edit product variant'
      }
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <div className="!col-span-full">
            <Dropdown
              loading={loading}
              className="w-full"
              menuClassName="max-h-[200px]"
              widthClass="!w-full"
              menuButton={
                <TextInput
                  type="text"
                  name="category"
                  placeholder="Product variant"
                  value={values.variantName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={
                    errors.variant && touched.variant ? errors.variant : ''
                  }
                  hasIcon={false}
                  readOnly
                  extra={
                    <Icon
                      id={'left-caret'}
                      width={16}
                      height={16}
                      className="-rotate-90 text-sec-black"
                    />
                  }
                  className="!border-divider-gray !w-full"
                />
              }
              onClickMenuItem={(selection) => {
                setFieldValue('variant', selection.value)
                setFieldValue('variantName', selection.name)
              }}
              menuItems={variants.map((variant) => {
                return variant?.isSubVariant
                  ? undefined
                  : {
                      name: variant.name,
                      value: variant._id,
                    }
              })}
            />
          </div>

          <TextInput
            type="text"
            name="price"
            placeholder="Price"
            value={String(values.price)}
            onBlur={handleBlur}
            onChange={handleChange}
            errors={errors.price && touched.price ? errors.price : ''}
            hasIcon={false}
          />

          <TextInput
            type="text"
            name="value"
            placeholder="Value"
            value={String(values.value)}
            onBlur={handleBlur}
            onChange={handleChange}
            errors={errors.value && touched.value ? errors.value : ''}
            hasIcon={false}
          />
        </div>

        <Button
          label="Proceed"
          type="submit"
          className="mt-10"
          disabled={!isValid}
          loading={false}
          primary
        />
      </form>
    </ModalComponent>
  )
}
