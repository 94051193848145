import React, { useState } from 'react'
import Layout from '../../layout'
import Pagination from '../../components/pagination'
import TransactionsTable from './TransactionsTable'
import Spinner from '../../components/spinner/Spinner'
import { useGetAllTransactionsQuery } from '../../redux-toolkits/transactions/transactions.slice'
import { PageHeader } from '../../components/typography/PageHeader'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'

const TransactionsPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<unknown>(1)
  const [limit, setLimit] = useState<number>(NUMBER_OF_ITEMS_PER_PAGE)

  const { data: transactions, isFetching: loadingTransactions } =
    useGetAllTransactionsQuery({
      page: currentPage as string,
      limit: limit,
    })

  const handlePageChange = (page: number): void => {
    setCurrentPage(page)
  }

  return (
    <Layout>
      <PageHeader title="Transactions" />

      <div>
        <TransactionsTable
          transactionList={transactions?.data?.transactions || []}
          currentPage={Number(currentPage)}
          totalDataCount={transactions?.data.totalCount ?? 0}
          pageLimit={limit}
          onPageChange={handlePageChange}
          loading={loadingTransactions}
        />
      </div>
    </Layout>
  )
}

export default TransactionsPage
