import React, { useEffect } from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import { AddDeliveryLocation } from '../../../redux-toolkits/products/products.type'
import {
  AddDeliveryLocationSchema,
  SelectLocationSchema,
} from '../../../validation/validate'

import Icon from '../../icon'
import Dropdown from '../../inputs/dropdown'
import { useLazyFetchAvailableLocationsQuery } from '../../../redux-toolkits/user/user.slice'
import { SingleLocation } from '../../../redux-toolkits/user/user.type'

export const SelectLocationModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: (val: SingleLocation) => void
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = (val) => {},
}) => {
  const [fetchLocations, { isFetching, data: locationsData }] =
    useLazyFetchAvailableLocationsQuery()

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      locationId: '',
      location: {},
    } as {
      locationId: string
      location: SingleLocation
    },
    validationSchema: SelectLocationSchema,
    onSubmit: (values, { resetForm }) => {
      onSuccessCallback(values.location as SingleLocation)
      resetForm()
    },
  })

  useEffect(() => {
    open && fetchLocations({ skip: 0, limit: 1000 })
  }, [open, values])

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={'Select location'}
    >
      <form
        className="flex flex-col gap-4 min-h-[300px] justify-between"
        onSubmit={handleSubmit}
      >
        <Dropdown
          loading={isFetching}
          className=""
          menuClassName="max-h-[200px]"
          widthClass="!w-full"
          menuButton={
            <TextInput
              type="text"
              name="type"
              placeholder="Location"
              value={values.location.name}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={
                errors.locationId && touched.locationId ? errors.locationId : ''
              }
              hasIcon={false}
              readOnly
              extra={
                <Icon
                  id={'left-caret'}
                  width={16}
                  height={16}
                  className="-rotate-90 text-sec-black"
                />
              }
              className="!border-divider-gray"
            />
          }
          onClickMenuItem={(selection) => {
            setFieldValue('locationId', (selection.value as SingleLocation)._id)
            setFieldValue('location', selection.value as SingleLocation)
          }}
          menuItems={locationsData?.data.docs.map((loc) => {
            return {
              name: loc.name,
              value: loc,
            }
          })}
        />

        <Button
          label="Proceed"
          type="submit"
          className="mt-10"
          disabled={!isValid}
          loading={false}
          primary
        />
      </form>
    </ModalComponent>
  )
}
