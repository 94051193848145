import React, { useState } from 'react'
import { useFetchListCategoriesQuery } from '../../redux-toolkits/lists/list.slice'
import { TableComponent } from '../../components/table'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'

import { PageHeader } from '../../components/typography/PageHeader'
import { Button } from '../../components/button'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import CountCard from '../orders/CountCard'
import { useModalControl } from '../../hooks/useModalControl'
import { AddCategoryModal } from '../../components/modal/app_modals/addCategoryModal'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'
import { ListCategoryDoc } from '../../redux-toolkits/lists/list.types'
import { SearchInput } from '../../components/inputs/searchInput'

const Categories: React.FC = () => {
  const [query, setQuery] = useState({
    skip: 0,
    limit: NUMBER_OF_ITEMS_PER_PAGE,
  })
  const [search, setSearch] = useState<string>()

  const [selectedCategory, setSelectedCategory] = useState<ListCategoryDoc>()
  const [mode, setMode] = useState<'create' | 'edit'>('create')

  const { setModalOpen, modalOpen, handleModalOpen, handleModalClose, modal } =
    useModalControl()

  const { data, isFetching, refetch } = useFetchListCategoriesQuery({
    skip: query.skip,
    limit: query.limit,
    search: search,
  })

  return (
    <div>
      <PageHeader title="Categories" />

      <div className="w-full gap-4 flex flex-wrap items-center justify-between">
        <div className="!w-full md:!max-w-[400px]">
          <SearchInput
            searchFunction={(search) => {
              setSearch(search === '' ? undefined : search)
              setQuery({ ...query, skip: 0 })
            }}
            placeholder="Search categories"
          />
        </div>

        <Button
          icon="plus"
          size="medium"
          label="Add new category"
          primary
          onClick={() => {
            setMode('create')
            handleModalOpen('addCategoryModal')
          }}
        />
      </div>

      <CountCardContainer>
        <CountCard
          isCurrency={false}
          text="Total categories count"
          count={data?.data.totalItems ?? 0}
        />
      </CountCardContainer>

      <TableComponent
        headers={['Name', 'Description', 'Category ID', ' ']}
        rows={
          data?.data?.data.length
            ? data.data.data.map((singleCategory) => {
                return {
                  id: singleCategory._id,
                  content: [
                    <div key="">{singleCategory.name}</div>,
                    <span
                      key=""
                      className="line-clamp-1 text-ellipsis max-w-[250px]"
                    >
                      {singleCategory.description}
                    </span>,
                    <div key="">{singleCategory._id}</div>,
                    <Dropdown
                      key={`${singleCategory._id}-controls`}
                      menuButton={
                        <Icon
                          id="ellipses"
                          height={18}
                          width={18}
                          className=""
                        />
                      }
                      onClickMenuItem={() => {}}
                      menuItems={[
                        {
                          name: (
                            <button
                              className="disabled:opacity-30 w-full text-left"
                              onClick={(): any => {
                                setMode('edit')
                                handleModalOpen('addCategoryModal')
                                setSelectedCategory(singleCategory)
                              }}
                            >
                              Edit
                            </button>
                          ),
                          value: '',
                        },
                      ]}
                    />,
                  ],
                }
              })
            : []
        }
        name="categories-table"
        loading={isFetching}
        isEmpty={[undefined, 0].includes(data?.data?.data.length)}
        currentPage={query.skip / query.limit + 1}
        totalDataCount={data?.data.totalItems ?? 0}
        pageLimit={query.limit}
        onPageChange={(number) => {
          setQuery({ ...query, skip: (number - 1) * query.limit })
        }}
      />

      <AddCategoryModal
        open={modalOpen && modal === 'addCategoryModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
          setSelectedCategory(undefined)
        }}
        onSuccessCallback={() => {
          setSelectedCategory(undefined)
          refetch()
        }}
        mode={mode}
        category={selectedCategory}
      />
    </div>
  )
}

export default Categories
