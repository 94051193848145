import React from 'react'
import Icon from '../icon'

type ModalProps = {
  open: boolean
  setOpen: (open: boolean) => void
  children: React.ReactNode
  onCloseCallback?: () => void
  title?: string
  childrenClassName?: string
  position?: 'right' | 'center'
}

const ModalComponent: React.FC<ModalProps> = ({
  open,
  setOpen,
  children,
  onCloseCallback,
  title = '',
  childrenClassName = '',
  position = 'center',
}) => {
  const closeModal = () => {
    setOpen(false)
    onCloseCallback && onCloseCallback()
  }

  return (
    open && (
      <div
        className={`
        fixed z-[999] h-screen max-h-[100dvh] w-screen 
        inset-0 flex items-end md:items-center
        ${position === 'center' && 'md:p-4 justify-center'}
        ${position === 'right' && 'md:p-0 justify-end'}
        `}
      >
        <div
          className="fixed z-10 bg-black/60 h-screen w-screen"
          onClick={closeModal}
        />
        <div
          className={`
          bg-white z-30 w-128 h-fit p-4 py-10 md:p-6
          flex flex-col
          ${position === 'center' && 'max-h-[90%] rounded-lg'}
          ${position === 'right' && '!h-[100%]'}
          ${childrenClassName}
          `}
        >
          <div className="flex items-center justify-between gap-4 mb-10">
            <span className="text-xl md:text-2xl font-recoleta-bold">
              {title}
            </span>

            <button type="button" onClick={closeModal}>
              <Icon id="close-circle" width={24} height={24} />
            </button>
          </div>

          <div className={`overflow-y-scroll h-full hide-scroll-bar`}>
            {children}
          </div>
        </div>
      </div>
    )
  )
}

export default ModalComponent
