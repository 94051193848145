import React, { useState } from 'react'
import Layout from '../../layout'
import { Link } from 'react-router-dom'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import { PageHeader } from '../../components/typography/PageHeader'
import Dropdown from '../../components/inputs/dropdown'
import { Button } from '../../components/button'
import { SearchInput } from '../../components/inputs/searchInput'
import CountCard from '../orders/CountCard'
import { TableComponent } from '../../components/table'
import { QueryProps } from '../../types/types'
import { formatCurrency } from '../../helpers'
import { useGetAllCustomersQuery } from '../../redux-toolkits/user/user.slice'
import { Avatar } from '../../components/avatar'

const filters = [
  { name: 'Default', value: 'default' },
  { name: 'Recent buyer', value: '' },
  { name: 'Customer name: A - Z', value: '' },
  { name: 'Customer name: Z - A', value: '' },
  { name: 'Total order: low to high', value: '' },
  { name: 'Total order: high to low', value: '' },
  { name: 'Total spend: low to high', value: '' },
  { name: 'Total spend: high to low', value: '' },
]

export const CustomersPage: React.FC = () => {
  const [query, setQuery] = useState<QueryProps>({
    skip: 0,
    limit: 20,
    search: undefined,
    deleted: undefined,
    startDate: undefined,
    endDate: undefined,
    sortBy: 'default',
  })

  const {
    data: customers,
    isFetching,
    isError,
  } = useGetAllCustomersQuery(query)

  return (
    <Layout>
      <>
        <PageHeader title={`Customers`} className="capitalize" />

        <CountCardContainer>
          <CountCard
            count={0}
            text="Total customers"
            icon=""
            isCurrency={false}
          />
          <CountCard
            count={0}
            text="Average customer spend"
            icon=""
            isCurrency={true}
          />
        </CountCardContainer>

        <div className="flex flex-wrap items-center justify-between gap-4 mb-10">
          <div className="!w-full md:!max-w-[400px]">
            <SearchInput
              searchFunction={(search) => {
                setQuery({
                  ...query,
                  search: search === '' ? undefined : search,
                  skip: 0,
                })
              }}
              placeholder="Search"
            />
          </div>

          <Dropdown
            menuClassName=""
            menuButton={
              <Button
                label={`Filter: ${filters.find(
                  (filter) => filter.value === query.sortBy,
                )?.name}`}
                primary={false}
                icon="filter"
                className="!py-3 !px-4 !text-base !font-normal !w-full md:max-w-fit"
              />
            }
            onClickMenuItem={(selected) => {}}
            menuItems={filters}
          />
        </div>

        <TableComponent
          name="Customers"
          showName={false}
          headers={[
            'Customer name',
            'Phone number',
            'Email address',
            'Total orders',
            'Average order value',
          ]}
          rows={
            customers
              ? customers.data.customers.map((item) => {
                  return {
                    id: item._id,
                    content: [
                      <div
                        className="
                          flex items-center gap-3
                          "
                        key={`${item._id}-name-id`}
                      >
                        <Avatar
                          name={`${item.firstname} ${item.lastname}`}
                          useRandomBgColor
                        />
                        <Link
                          to={`/customers/${item._id}`}
                          className="
                          hover:text-black hover:underline
                          transition-all duration-500
                          "
                        >{`${item.firstname} ${item.lastname}`}</Link>
                      </div>,
                      item.phoneNumber,
                      item.email,
                      `${item.totalOrders}`,
                      formatCurrency(item.averageOrderValue ?? 0),
                    ],
                  }
                })
              : []
          }
          onPageChange={(page) => {
            setQuery({ ...query, skip: (page - 1) * Number(query.limit) })
          }}
          pageLimit={Number(query.limit)}
          currentPage={Number(query.skip) / Number(query.limit) + 1}
          loading={isFetching}
          isEmpty={isError}
          totalDataCount={customers?.data.count ?? 0}
        />
      </>
    </Layout>
  )
}
