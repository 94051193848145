import { toast } from 'react-toastify'
import { apiSlice } from '../api/apiSlice'
import {
  type OrderResponse,
  type OrderPayload,
  type OrderResponsePayload,
  type OrderMetricsCount,
  type OrderMetricsValue,
  CreatorMetricsResponse,
  OrderSettlementsResponse,
  SettlementsResponse,
  SingleSettlementsResponse,
  RetrySettlementResponse,
  UpdateOrderStatusResponse,
} from './orders.type'
import { getErrorMessage } from '../../helpers'
import { ErrorType } from '../user/user.type'
import { QueryProps } from '../../types/types'

interface MainOrderResponse {
  data: OrderResponsePayload
}

export const orderApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getOrders: builder.query<
      MainOrderResponse,
      QueryProps & { status?: string; startDate?: string; endDate?: string }
    >({
      query: ({ limit, page, status, startDate, endDate, search }) => ({
        url: `/order/all`,
        method: 'GET',
        params: {
          sortDirection: 'desc',
          sortField: 'date',
          page: page,
          limit: limit,
          status: status,
          startDate: startDate,
          endDate: endDate,
          filter: search,
        },
      }),
      providesTags: [{ type: 'ORDER', id: 'LIST' }],
    }),
    getOrdersMetricsCount: builder.query<
      OrderMetricsCount,
      { startDate?: string; endDate?: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `/order/metrics/count`,
        method: 'GET',
        params: {
          startDate: startDate,
          endDate: endDate,
        },
      }),
    }),
    getOrdersMetricsValue: builder.query<
      OrderMetricsValue,
      { startDate?: string; endDate?: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `/order/metrics/value`,
        method: 'GET',
        params: {
          startDate: startDate,
          endDate: endDate,
        },
      }),
    }),
    getTopCreatorsByOrders: builder.query<CreatorMetricsResponse, void>({
      query: () => ({
        url: `/order/metrics/best-seller/order-count`,
        method: 'GET',
        params: {},
      }),
    }),
    getTopCreatorsBySales: builder.query<CreatorMetricsResponse, void>({
      query: () => ({
        url: `/order/metrics/best-seller/sale-value`,
        method: 'GET',
        params: {},
      }),
    }),
    getOrder: builder.query<{ data: OrderResponse }, { id: string }>({
      query: ({ id }) => ({
        url: `/order/${id}`,
        method: 'GET',
      }),
    }),
    createOrder: builder.mutation<OrderResponse, OrderPayload>({
      query: (payload) => ({
        url: `/order`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'ORDER', id: 'LIST' }],
    }),
    updateOrder: builder.mutation<
      UpdateOrderStatusResponse,
      { body: { status: string }; id: string }
    >({
      query: (payload) => ({
        url: `/order/${payload.id}/status`,
        method: 'PATCH',
        body: payload.body,
      }),
      invalidatesTags: [{ type: 'ORDER', id: 'LIST' }],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success('Order updated successfully')
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    requestOrderDelivery: builder.mutation<OrderResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/order/${id}/request-delivery`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'ORDER', id: 'LIST' }],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success('Delivery requested successfully')
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    getOrderSettlements: builder.query<
      OrderSettlementsResponse,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/order/${id}/settlements`,
        method: 'GET',
      }),
    }),
    getSettlements: builder.query<
      SettlementsResponse,
      {
        party: string | undefined
        page: number
        limit: number
        search?: string
      }
    >({
      query: (payload) => ({
        url: `/settlements`,
        method: 'GET',
        params: {
          ...payload,
        },
      }),
    }),
    getSingleSettlement: builder.query<
      SingleSettlementsResponse,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/settlements/${id}`,
        method: 'GET',
      }),
    }),
    retrySettlement: builder.mutation<
      RetrySettlementResponse,
      { id: string; password: string }
    >({
      query: ({ id, password }) => ({
        url: `/settlements/${id}/retry`,
        method: 'POST',
        body: {
          password,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
  }),
})

export const {
  useGetOrderQuery,
  useGetOrdersQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useGetOrdersMetricsCountQuery,
  useGetOrdersMetricsValueQuery,
  useRequestOrderDeliveryMutation,
  useGetTopCreatorsByOrdersQuery,
  useGetTopCreatorsBySalesQuery,
  useGetOrderSettlementsQuery,
  useGetSettlementsQuery,
  useGetSingleSettlementQuery,
  useRetrySettlementMutation,
} = orderApiSlice
