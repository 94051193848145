import React from 'react'
import Layout from '../../../layout'
import CuratedListsTable from './CuratedListsTable'
import { PageHeader } from '../../../components/typography/PageHeader'

export const CuratedListsPage: React.FC = () => {
  return (
    <Layout>
      <PageHeader title="Everyday Lists" />

      <CuratedListsTable />
    </Layout>
  )
}
