import React, { ReactNode } from 'react'

export const ChartCard: React.FC<{
  title: string
  value?: string
  chart: ReactNode
  filter?: ReactNode
}> = ({ title, value = '', chart, filter }) => {
  return (
    <div
      className="
      p-6
      rounded-xl
      border border-divider-gray
        "
    >
      <div className="flex items-center justify-between gap-4 flex-wrap">
        <p
          className="
          text-xl mb-3 font-medium
        "
        >
          {title}
        </p>

        {filter ? filter : undefined}
      </div>

      <span
        className="
        font-medium text-base
        block mb-9
        "
      >
        {value}
      </span>

      {chart}
    </div>
  )
}
